import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
const ExploreMoreGoals = React.lazy(() => import('../pages/ExploreMoreGoals'));
const ProgressComponent = React.lazy(() => import('../pages/Progress'));
import queryString from 'query-string';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { isStudent } from 'src/utils/roleUtil';
import { GenRoutesProps, RouteResultType, RouteType } from './pagePropsTypes';
import styled from 'styled-components';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CopyRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 12px;
  background-color: #e3e7ed;
  height: 60px;
  p {
    max-width: 1136px;
    width: 100%;
    margin-bottom: 0px;
    height: 32px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: #73747d;
  }
`;

function routePathWithDefaultParam(url: string): string {
  const { userId, version } = queryString.parse(window.location.search);
  return `${url}?userId=${userId}${version ? '&version=1' : ''}`;
}

export const allRoutePath: {
  [key: string]: string;
} = {
  progress: '/',
  explore: '/explore',
  presettings: '/presettings',
};

export const allRoutePathGen: {
  [key: string]: string;
} = Object.keys(allRoutePath).reduce((previousValue, currentValue) => {
  return {
    ...previousValue,
    [currentValue]: routePathWithDefaultParam(allRoutePath[currentValue]),
  };
}, {});

export const genRoutes = ({ isStudent, routes }: GenRoutesProps): RouteResultType[] => {
  return routes.map((route) => ({
    ...route,
    header: isStudent ? route?.header?.student : route?.header?.other,
  }));
};

const AppWrapperRoute: React.FC = () => {
  const {
    currentUser: { roles },
  } = useContext(CurrentUserContext);
  // const history = useHistory();
  const routes: RouteType[] = [
    {
      exact: true,
      path: allRoutePath.explore,
      id: 'explore',
      component: ExploreMoreGoals,
      header: {
        student: {
          text: 'Explore Goals',
          breadcrumbs: [{ text: 'Pathfinder', link: allRoutePathGen.progress }],
        },
        other: {
          text: 'Explore Goals',
          breadcrumbs: [
            {
              text: 'Our People',
              link: () => {
                const domain = window.xprops && window.xprops.domain ? window.xprops.domain : 'http://localhost:3000';
                const location = `${domain}/our-people`;
                window.open(location);
              },
            },
            { text: 'Pathfinder', link: allRoutePathGen.progress },
          ],
        },
      },
    },
    {
      exact: true,
      path: allRoutePath.presettings,
      id: 'explore',
      component: ExploreMoreGoals,
      defaultProps: {
        showViewProgress: true,
      },
      header: {
        student: {
          text: 'Presetting',
          breadcrumbs: [],
        },
        other: {
          text: 'Presetting',
          breadcrumbs: [
            {
              text: 'Our People',
              link: () => {
                const domain = window.xprops && window.xprops.domain ? window.xprops.domain : 'http://localhost:3000';
                const location = `${domain}/our-people`;
                window.open(location);
                //TODO: DELETE presetting route item here
              },
            },
          ],
        },
      },
    },
    {
      exact: true,
      path: allRoutePath.progress,
      id: 'progress',
      component: ProgressComponent,
      header: {
        student: {
          text: 'Pathfinder',
          breadcrumbs: [],
        },
        other: {
          text: 'Pathfinder',
          breadcrumbs: [
            {
              text: 'Our People',
              link: () => {
                // TODO: fix this
                const domain = window.xprops && window.xprops.domain ? window.xprops.domain : 'http://localhost:3000';
                const location = `${domain}/our-people`;
                window.open(location);
              },
            },
          ],
        },
      },
    },
  ];
  return (
    <Switch>
      {genRoutes({ routes, isStudent: isStudent(roles) }).map((route) => (
        <Route
          key={route.path}
          exact={route.exact}
          path={route.path}
          render={() =>
            route.header ? (
              <LayoutContainer>
                <route.component data-test-id={route.id} header={route.header} {...route?.defaultProps} />
                <CopyRightContainer>
                  <p>Patent Pending (New Zealand Patent Application 784889).</p>
                  <p>© Copyright Crimson Consulting Limited 2022 International Rights Reserved.</p>
                </CopyRightContainer>
              </LayoutContainer>
            ) : (
              <LayoutContainer>
                <route.component data-test-id={route.id} {...route?.defaultProps} />
                <CopyRightContainer>
                  <p>Patent Pending (New Zealand Patent Application 784889).</p>
                  <p>© Copyright Crimson Consulting Limited 2022 International Rights Reserved.</p>
                </CopyRightContainer>
              </LayoutContainer>
            )
          }
        />
      ))}
      <Route>{/* <ErrorPage /> */}</Route>
    </Switch>
  );
};

export default AppWrapperRoute;
