import { useMutation } from '@apollo/client';
import moment from 'moment';
import { useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CREATE_STUDENT_OVERALL_GOAL } from 'src/graphql/StudentOverallGoal';
import AboutPathFinderModal from 'src/modals/AboutPathFinderModal';
import { SetOverallGoalValue } from 'src/modals/SetOverallGoalModal';
import { allRoutePathGen } from 'src/routes';
import { popupModalInfo, ModalReturnType } from 'src/utils/commonModal';
import { PermissionAction, Actions } from 'src/utils/permissions';
import { Level } from 'src/__generated__/graphqlTypes';
import { UPDATE_YEAR_OF_APPLICATION } from 'src/graphql/User';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { CurrentUserContext } from 'src/context/CurrentUserContext';

import {
  StyledHeader,
  StyledIntro,
  StyledContainer,
  StyledIGotIn,
  StyledCenteredImage,
  StyledButtonGroup,
  StyledGetStartedButton,
} from './style';

const StudentLandingPage = ({
  userId,
  studentId,
  setHasProgress,
  permission,
}: {
  userId: string;
  studentId: string;
  setHasProgress: (progress: boolean) => void;
  permission: PermissionAction;
}): JSX.Element => {
  const history = useHistory();
  const { studentInfo } = useContext(CurrentUserContext);
  const [submitStudentOverallGoal, { data: submitStudentOverallGoalResult, loading: submitStudentOverallGoalLoading }] =
    useMutation(CREATE_STUDENT_OVERALL_GOAL);
  const { crimsonAppApiClient } = useContext(ApolloClientContext);
  const [updateYearOfApplication] = useMutation(UPDATE_YEAR_OF_APPLICATION, { client: crimsonAppApiClient });
  const yearOfApplication = studentInfo.studentInfo.yearOfApplication;
  const aboutModalRef = useRef<ModalReturnType | null>(null);
  const overallGoalModalRef = useRef<ModalReturnType | null>(null);
  useEffect(() => {
    if (!submitStudentOverallGoalLoading && submitStudentOverallGoalResult) {
      overallGoalModalRef.current?.destroy();
      setHasProgress(true);
      history.push(allRoutePathGen.presettings);
    }
  }, [submitStudentOverallGoalResult, submitStudentOverallGoalLoading, setHasProgress, history]);
  const showPathFinderModal = () => {
    aboutModalRef.current = popupModalInfo({
      width: '860px',
      content: (
        <AboutPathFinderModal
          yearOfApplication={yearOfApplication}
          onSubmit={onSubmit}
          buttonGetStartedVisible={permission[Actions.Initialize] || userId === studentId}
        />
      ),
      className: 'component-about-pathfinder-modal',
    });
  };
  const onSubmit = (values: SetOverallGoalValue) => {
    const { startDate, applicationYear, pathfinderType } = values;
    if (yearOfApplication !== applicationYear) {
      updateYearOfApplication({
        variables: {
          userId: studentId as string,
          yearOfApplication: applicationYear + '/' + (Number(applicationYear) + 1),
        },
      });
    }
    submitStudentOverallGoal({
      variables: {
        userId: studentId as string,
        targetLevel: Level.Globetrotter,
        startDate: moment(startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format(),
        applicationYear: Number(applicationYear),
        isSelfInit: true,
        pathfinderType,
      },
    });
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledIGotIn />
        Discover your path to university
      </StyledHeader>
      <StyledIntro>
        Pathfinder helps students plan and track their progress towards acceptance to their dream schools across some of
        the following indices (depending on which countries you are applying to): Academics, Standardized Tests,
        Extracurricular, Career/School Choices, Personal Development and Subject Interest Development.
      </StyledIntro>
      <StyledCenteredImage />
      <StyledButtonGroup>
        <StyledGetStartedButton onClick={showPathFinderModal} type="primary">
          Get Started
        </StyledGetStartedButton>
      </StyledButtonGroup>
    </StyledContainer>
  );
};

export default StudentLandingPage;
