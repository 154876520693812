import styled from 'styled-components';

const StyledTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-top: 15px;
`;
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledDescription = styled.div`
  color: #73747d;
  margin-top: 10px;
  text-align: center;
`;
const PermissionDenied = (): JSX.Element => {
  return (
    <StyledContainer>
      <img src="/accessDenied.svg" />
      <StyledTitle>Access Denied</StyledTitle>
      <StyledDescription>Sorry, you have no permission to access the Pathfinder.</StyledDescription>
    </StyledContainer>
  );
};
export default PermissionDenied;
