import { InputNumber as AntInputNumber } from 'antd';
import styled from 'styled-components';

type Props = {
  name: string;
  placeholder?: string;
  min?: number;
  max?: number;
};
const StyledInput = styled(AntInputNumber)`
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 0;
  width: 100%;
  border: solid 1px #e3e7ed;
`;

const InputNumber = ({ name, placeholder, ...props }: Props): JSX.Element => {
  return <StyledInput placeholder={placeholder} name={name} {...props} />;
};

export default InputNumber;
