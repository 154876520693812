import { Input as AntInput } from 'antd';
import styled from 'styled-components';

type Props = {
  name: string;
  placeholder?: string;
};
const StyledInput = styled(AntInput)`
  border-radius: 4px;
  background-color: #fff;
  padding: 13px 240px 12px 20px;
  border: solid 1px #e3e7ed;
`;

const Input = ({ name, placeholder, ...props }: Props): JSX.Element => {
  return <StyledInput placeholder={placeholder} name={name} {...props} />;
};

export default Input;
