import styled from 'styled-components';
import { Button } from 'antd';

import './index.less';

const StyledImageContainer = styled.div`
  width: 190px;
  height: 190px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

const StyledTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 26px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const StyledPrimaryButton = styled(Button)`
  font-family: Montserrat-Bold;
  width: 135px;
  height: 40px;
  border-radius: 50px;
`;

const SetStartPointModal = ({ onOk }: { onOk: () => void }): JSX.Element => {
  return (
    <div>
      <StyledImageContainer>
        <img src="/landingPage/work_with_strategist.svg" />
      </StyledImageContainer>
      <StyledTitle>Calculate starting points</StyledTitle>
      <p>
        Go through each goal with your student and mark the ones the student has already completed to calculate the
        number of points your student will start with.
      </p>
      <p>
        You can always add goals the student completed before beginning Pathfinder later if you miss anything during
        this initial round.
      </p>
      <StyledButtonContainer>
        <StyledPrimaryButton type="primary" onClick={onOk}>
          Got It
        </StyledPrimaryButton>
      </StyledButtonContainer>
    </div>
  );
};

export default SetStartPointModal;
