export enum Actions {
  Initialize = 'Initialize',
  StrategistLandingPage = 'StrategistLandingPage',
  Submit = 'Submit',
  MarkAchieve = 'MarkAchieve',
  AchieveBeforeStart = 'AchieveBeforeStart',
  Approve = 'Approve',
  ApproveAll = 'ApproveAll',
  EnableNewLevel = 'EnableNewLevel',
  Assign = 'Assign',
  Reject = 'Reject',
  Close = 'Close',
  EditCountry = 'EditCountry',
}
// MARK: - Permissions routes
export enum Pages {
  Default = '/',
  Explore = '/explore',
  Progress = '/progress',
}

const permissionsConfig: { [role: string]: Permission } = {
  BASE: {
    [Pages.Default]: {
      [Actions.Initialize]: false,
      [Actions.StrategistLandingPage]: false,
      [Actions.Assign]: false,
      [Actions.Submit]: false,
      [Actions.MarkAchieve]: false,
      [Actions.Approve]: false,
      [Actions.ApproveAll]: false,
      [Actions.EnableNewLevel]: false,
      [Actions.Close]: false,
    },
  },
  STUDENT: {
    [Pages.Default]: {
      [Actions.Submit]: true,
      [Actions.EnableNewLevel]: false,
      [Actions.EditCountry]: true,
    },
  },
  TUTOR: {
    [Pages.Default]: {
      [Actions.Submit]: false,
      [Actions.EditCountry]: true,
      [Actions.EnableNewLevel]: false,
    },
  },
  STRATEGIST: {
    [Pages.Default]: {
      [Actions.EditCountry]: true,
      [Actions.StrategistLandingPage]: true,
      [Actions.Initialize]: true,
      [Actions.Assign]: true,
      [Actions.Submit]: false,
      [Actions.AchieveBeforeStart]: true,
      [Actions.Reject]: true,
      [Actions.MarkAchieve]: true,
      [Actions.Approve]: true,
      [Actions.ApproveAll]: true,
      [Actions.Close]: true,
      [Actions.EnableNewLevel]: true,
    },
  },
  SUPER_ADMIN: {
    [Pages.Default]: {
      [Actions.EditCountry]: true,
      [Actions.StrategistLandingPage]: true,
      [Actions.Initialize]: true,
      [Actions.Assign]: true,
      [Actions.Submit]: false,
      [Actions.AchieveBeforeStart]: true,
      [Actions.Reject]: true,
      [Actions.MarkAchieve]: true,
      [Actions.Approve]: true,
      [Actions.Close]: true,
      [Actions.ApproveAll]: true,
      [Actions.EnableNewLevel]: true,
    },
  },
  CASE_MANAGER: {
    [Pages.Default]: {
      [Actions.EditCountry]: true,
    },
  },
  HEAD_TUTOR: {
    [Pages.Default]: {
      [Actions.EditCountry]: true,
    },
  },
  OPERATIONAL_SUPPORT: {
    [Pages.Default]: {
      [Actions.EditCountry]: true,
    },
  },
};

type Role = {
  roleId: string;
  isPrimary: boolean;
};

export type PermissionAction = { [key in Actions]?: boolean };
export type Permission = { [key in Pages]?: { [key in Actions]?: boolean } };

export const getPermissions = (roles: Role[]): Permission => {
  return roles
    .map((role: Role) => permissionsConfig[role.roleId] || {})
    .reduce((previewPermission: Permission, rolePermission: Permission) => {
      Object.keys(rolePermission)
        .map((page: string) => page as unknown as Pages)
        .forEach((page: Pages) => {
          if (!(page in previewPermission)) {
            previewPermission[page] = rolePermission[page];
            return;
          }
          Object.keys(rolePermission[page] || {})
            .map((action: string) => action as unknown as Actions)
            .forEach((action: Actions) => {
              (previewPermission[page] || {})[action] =
                (rolePermission[page] || { [action]: false })[action] ||
                (previewPermission[page] || { [action]: false })[action];
            });
        });
      return previewPermission;
    }, permissionsConfig.BASE);
};
