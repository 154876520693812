import './App.less';
import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AppWrapperRoute from './routes';

type onMessageProps = {
  event: string;
  data: string;
};

type AppProps = {
  onMessage?: (props: onMessageProps) => void;
  registerEventEmitter?: (eventName: string, callback: (data: string) => void) => void;
  unregisterEventEmitter?: (eventName: string) => void;
};

const App: FC<AppProps> = (props: AppProps) => {
  const { onMessage, registerEventEmitter, unregisterEventEmitter } = props;
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (window !== window.top && registerEventEmitter) {
      registerEventEmitter('refresh', (url: string) => {
        history.replace(url.replace('/pathfinder', '') || '/');
      });
    }
    return () => {
      unregisterEventEmitter?.('refresh');
    };
  });

  useEffect(() => {
    const url = location.pathname + location.search;
    onMessage?.({ event: 'route', data: url });
  }, [location.pathname, location.search, onMessage]);

  return <AppWrapperRoute />;
};

export default App;
