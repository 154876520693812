import styled from 'styled-components';
import Header from '../PageHeader';
import * as CSS from 'csstype';
import { HeaderType } from 'src/routes/pagePropsTypes';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f7fa;
  width: 100%;
  min-height: 100vh;
`;
const Layout = ({
  style,
  headerProps,
  headerStyle,
  children,
}: {
  style?: CSS.Properties | undefined;
  headerStyle?: CSS.Properties | undefined;
  headerProps?: HeaderType;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <LayoutContainer style={style}>
      {headerProps && <Header {...{ otherProps: headerProps, style: headerStyle }} />}
      {children}
    </LayoutContainer>
  );
};

export default Layout;
