import styled from 'styled-components';
import { Button } from 'antd';
import theme from '../../theme';

export const StyledDisplayContainer = styled.div`
  height: 250px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${theme.breakPoints.xm}) {
    flex-direction: column;
    height: auto;
    margin-top: 36px;
  }
`;
export const StyledImageContainer = styled.div`
  margin-right: 58px;
  @media (max-width: ${theme.breakPoints.xm}) {
    margin-right: 0;
    margin-bottom: 48px;
  }
  img {
    width: 306px;
    @media (max-width: ${theme.breakPoints.xm}) {
      width: 200px;
    }
  }
`;
export const StyledButtonContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${theme.breakPoints.xm}) {
    bottom: auto;
  }
  .ant-btn {
    background: transparent;
    border-color: transparent;
    color: #464ac9;
    box-shadow: none;
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    background: transparent;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.25);
  }
`;
export const StyledButton = styled(Button)`
  width: 186px;
  height: 40px;
  border-radius: 50px;
  position: absolute;
  bottom: 46px;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledTitle = styled.div`
  font-family: Montserrat-Bold;
  font-size: 36px;
  text-align: center;
  margin-top: 72px;
  @media (max-width: ${theme.breakPoints.xm}) {
    font-size: 24px;
  }
`;

export const StyledGetStartedButton = styled(Button)`
  width: 186px;
  position: absolute;
  bottom: 65px;
  right: 48px;
  height: 40px;
  border-radius: 50px;
  @media (max-width: ${theme.breakPoints.xm}) {
    bottom: 82px;
    left: 50%;
    transform: translate(-50%);
  }
`;
export const StyledPaginationText = styled.div`
  color: #73747d;
`;
export const StyledVideoContainer = styled.iframe`
  width: 446px;
  height: 278px;
  @media (max-width: ${theme.breakPoints.xm}) {
    width: 100%;
    height: 200px;
  }
`;
