import { gql } from '@apollo/client';

export const CREATE_STUDENT_OVERALL_GOAL = gql`
  mutation createStudentOverallGoal(
    $userId: String!
    $targetLevel: Level!
    $startDate: String!
    $applicationYear: Int!
    $isSelfInit: Boolean!
    $pathfinderType: PathfinderType!
  ) {
    createStudentOverallGoal(
      input: {
        userId: $userId
        targetLevel: $targetLevel
        startDate: $startDate
        applicationYear: $applicationYear
        isSelfInit: $isSelfInit
        pathfinderType: $pathfinderType
        version: 1
      }
    ) {
      userId
      endDate
      startDate
      targetLevel
      pathfinderType
    }
  }
`;
export const QUERY_STUDENT_OVERALL_GOAL = gql`
  query queryStudentOverallGoal($userId: String!) {
    studentOverallGoal(userId: $userId) {
      userId
      startDate
      endDate
      targetLevel
      isSelfInit
      pathfinderType
      version
    }
    latestVersion
  }
`;

export const EDIT_STUDENT_OVERALL_GOAL = gql`
  mutation editStudentOverallGoal(
    $userId: String!
    $targetLevel: Level!
    $startDate: String!
    $applicationYear: Int!
    $isSelfInit: Boolean!
    $pathfinderType: PathfinderType!
  ) {
    editStudentOverallGoal(
      input: {
        userId: $userId
        targetLevel: $targetLevel
        startDate: $startDate
        applicationYear: $applicationYear
        isSelfInit: $isSelfInit
        pathfinderType: $pathfinderType
        version: 1
      }
    ) {
      userId
      endDate
      startDate
      targetLevel
      pathfinderType
    }
  }
`;

export const GET_STUDENT_CURRENT_GOALS = gql`
  query ($studentId: String!, $pathfinderType: String, $assignStatus: [String!]!, $version: Int) {
    goalsSummary(
      studentId: $studentId
      assignStatus: $assignStatus
      pathfinderType: $pathfinderType
      version: $version
    ) {
      category
      goals {
        title
        category
        subcategory
        description
        assignStatus
        targetLevel
        targetLevelPoints
        achievedPoints
        possiblePoints
        id
        achievedLevel
        assignedPoints
        reviewPoints
        reviewLevel
        nextLevel
        nextLevelPoints
      }
    }
  }
`;

export const GET_STUDENT_CURRENT_GOALS_CATEGORY_COUNT = gql`
  query ($studentId: String!, $pathfinderType: String, $version: Int) {
    totalCountEachCurrent(studentId: $studentId, pathfinderType: $pathfinderType, version: $version) {
      assigned
      reviewing
      progressing
      completed
    }
  }
`;
