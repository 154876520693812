export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
};

export type Greeting = {
  __typename?: 'Greeting';
  response?: Maybe<Scalars['String']>;
};

export enum Level {
  Globetrotter = 'GLOBETROTTER',
  MasterGlobetrotter = 'MASTER_GLOBETROTTER',
  Scout = 'SCOUT',
  Seeker = 'SEEKER',
  Venturer = 'VENTURER',
  Voyager = 'VOYAGER',
}

export enum PathfinderType {
  US = 'US',
  UK = 'UK',
  US_UK = 'US_UK',
}
export type Mutation = {
  __typename?: 'Mutation';
  createStudentOverallGoal?: Maybe<StudentOverallGoal>;
  editStudentOverallGoal?: Maybe<StudentOverallGoal>;
};

export type MutationCreateStudentOverallGoalArgs = {
  input: CreateStudentOverallGoalInput;
};

export type MutationEditStudentOverallGoalArgs = {
  input: EditStudentOverallGoalInput;
};

export type OrderBy = {
  key: Scalars['String'];
  order?: Maybe<SortOrder>;
};

export type Query = {
  __typename?: 'Query';
  greeting?: Maybe<Greeting>;
  /** getStudentOverallGoal */
  studentOverallGoal?: Maybe<StudentOverallGoal>;
};

export type QueryGreetingArgs = {
  name?: Maybe<Scalars['String']>;
};

export type QueryStudentOverallGoalArgs = {
  userId: Scalars['String'];
};

export type StudentOverallGoal = {
  __typename?: 'StudentOverallGoal';
  deletedAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  targetLevel: Level;
  pathfinderType: PathfinderType;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type CreateStudentOverallGoalInput = {
  applicationYear: Scalars['Int'];
  /** start date */
  startDate: Scalars['String'];
  /** target level */
  targetLevel: Level;
  /** userId */
  userId: Scalars['String'];
  /** isSelfInit */
  isSelfInit: Scalars['Boolean'];
  pathfinderType: PathfinderType;
};

export type EditStudentOverallGoalInput = {
  /** year of application */
  applicationYear: Scalars['Int'];
  /** start date */
  startDate?: Maybe<Scalars['String']>;
  /** target level */
  targetLevel: Level;
  /** the id of student */
  userId: Scalars['String'];
  /** isSelfInit */
  isSelfInit: Scalars['Boolean'];
  pathfinderType: PathfinderType;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}
