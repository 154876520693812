import { notification } from 'antd';
import { match } from 'ts-pattern';

import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';

import type { XProps } from '../types/global';
import { getFeatureSwitchesAsString } from '../featureSwitches';

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, extensions }) => {
      return notification.error({
        message: match(extensions?.exception?.name)
          .with('UNAUTHORIZED', () => 'Permission Denied')
          .otherwise(() => 'GraphQL Error'),
        description: `${message} operation: ${operation.operationName}`,
      });
    });
  }

  if (networkError) {
    notification.error({
      message: match(networkError)
        .when(
          (e) => 'statusCode' in e && e.statusCode === 401,
          () => 'Not Authenticated',
        )
        .when(
          (e) => 'statusCode' in e && e.statusCode === 403,
          () => 'Permission Denied',
        )
        .otherwise(() => 'Network Error'),
      description: `${networkError.message} operation: ${operation.operationName}`,
    });
    if ('statusCode' in networkError && networkError.statusCode === 403) {
      window.xprops?.authorize();
    }
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: async (error) => {
      if ('statusCode' in error && error.statusCode === 403) {
        const result = await window.xprops?.authorize();
        return !result;
      }
      return false;
    },
  },
});

const getHttpLink = (endpoint: string, xprops?: XProps) => {
  const { featureSwitches } = xprops ?? {};
  const mergedFlags = { ...featureSwitches, ...JSON.parse(getFeatureSwitchesAsString()) };
  const defaultHeaders: { [key: string]: string } = {
    'X-Feature-Switches': JSON.stringify(mergedFlags),
  };
  if (/localhost/.test(endpoint)) {
    defaultHeaders['x-user'] = JSON.stringify({
      userId: 'auth0-strategist',
      roles: [
        {
          id: 'STRATEGIST',
        },
        {
          id: 'STUDENT',
        },
      ],
    });
  }
  return new HttpLink({
    uri: endpoint,
    headers: defaultHeaders,
  });
};

const authMiddleware = setContext(async () => {
  // get token from parent frame app.
  let token = await window.xprops?.getBearer();
  if (!token || token.length < 32) {
    await window.xprops?.authorize();
    token = await window.xprops?.getBearer();
  }
  const role = window.xprops.loggedInUser?.roleInfo || '';
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'x-user-role': typeof role === 'string' ? btoa(role) : '',
    },
  };
});

export function getGraphQLClient(endpoint: string, xprops?: XProps): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link: from([errorLink, authMiddleware, getHttpLink(endpoint, xprops), retryLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            resourceConnection: relayStylePagination(),
            tagConnection: relayStylePagination(),
          },
        },
      },
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
}
