import {
  STUDENT,
  SUPER_ADMIN,
  CASE_MANAGER,
  OPERATIONAL_SUPPORT,
  STRATEGIST,
  HEAD_TUTOR,
  TUTOR,
  GUARDIAN,
} from './roles.json';

export const checkRole = (
  roles: {
    roleId: string;
  }[],
  roleId: string,
): boolean => {
  return roles.some((role) => {
    return role.roleId === roleId;
  });
};

export const isStrategist = (
  userRoles: {
    roleId: string;
  }[],
): boolean => checkRole(userRoles, STRATEGIST);

export const isStudent = (
  userRoles: {
    roleId: string;
  }[],
): boolean => checkRole(userRoles, STUDENT);

export const isHeadTutor = (
  userRoles: {
    roleId: string;
  }[],
): boolean => checkRole(userRoles, HEAD_TUTOR);

export const isTutor = (
  userRoles: {
    roleId: string;
  }[],
): boolean => checkRole(userRoles, TUTOR);

export const allowedUserRoles = [
  STUDENT,
  SUPER_ADMIN,
  CASE_MANAGER,
  OPERATIONAL_SUPPORT,
  STRATEGIST,
  HEAD_TUTOR,
  TUTOR,
  GUARDIAN,
];
