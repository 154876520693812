import { createContext } from 'react';
import { Pathfinder } from 'src/types/pathfinder';

export const CurrentPathfinderContext = createContext<Pathfinder | Record<string, never>>({});

export const defaultPathfinderValue = {
  pathfinderType: 'US',
  version: 1,
  canEdit: true,
};
