import { DatePicker as AntDatePicker } from 'antd';
import styled from 'styled-components';
import './index.less';
import { Today } from '@styled-icons/material';

const StyledDatePicker = styled(AntDatePicker)`
  width: 100%;
  padding: 10px 20px;
  border-radius: 4px;
  border: solid 1px #e3e7ed;
  cursor: pointer;
`;

type Props = {
  name: string;
  format?: string;
  placeholder?: string;
};
const DatePicker = (props: Props): JSX.Element => {
  return <StyledDatePicker suffixIcon={<Today />} allowClear={false} {...props} />;
};

export default DatePicker;
