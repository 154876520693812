import { useState, useRef } from 'react';
import SetOverallGoalModal, { SetOverallGoalValue } from 'src/modals/SetOverallGoalModal';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { popupModalInfo, ModalReturnType } from 'src/utils/commonModal';
import { defaultStudentFields } from 'src/modals/SetOverallGoalModal/formFields';
import {
  StyledDisplayContainer,
  StyledImageContainer,
  StyledButtonContainer,
  StyledTitle,
  StyledGetStartedButton,
  StyledPaginationText,
  StyledVideoContainer,
} from './style';
import './index.less';

const Steps = [
  {
    title: 'What is pathfinder?',
    imageSrc: '/landingPage/about_01.svg',
    content: () => {
      return (
        <div>
          <p>
            Pathfinder is essentially a master list of everything you can do to become the best possible university
            applicant in the years before you apply.
          </p>
          <p>
            Pathfinder contains over 280 goals, each with a point value based on how much admissions officers will value
            that achievement. The more goals you complete, the more points you’ll accrue, and the better your eventual
            college application will be.
          </p>
          <p>
            Every student’s Pathfinder looks different—yours will be customized to your interests, needs, and
            objectives.
          </p>
        </div>
      );
    },
  },
  {
    title: 'How does Pathfinder work?',
    imageSrc: '/landingPage/about_02.svg',
    content: () => {
      return (
        <div>
          <p>
            Pathfinder’s goals are split into different categories covering everything admissions officers care about.
            Each category has a different weighting reflecting the percentage of points you should aim to score at each
            target level.
          </p>
          <p>
            The US only as well as US and UK/EU pathfinders have the following categories: Academics (35% weighting),
            Extracurriculars (20%), Personal Development (20%), Standardized Tests (15%) and Majors/Careers/School
            Research (10%).
          </p>
          <p>
            The UK/EU only pathfinder has the following categories: Academics (45%), Subject Interest Development (30%),
            Personal Development (15%), Major/Careers/School Research (10%).
          </p>
        </div>
      );
    },
  },
  {
    title: 'Get to Know Pathfinder',
    videoUrl: 'https://www.youtube.com/embed/eVszXY-ai68',
  },
  {
    title: 'What about everything I’ve done before starting Pathfinder?',
    imageSrc: '/landingPage/about_04.png',
    content: () => {
      return (
        <div>
          <p>
            As seen in the video, the first step will be to fill in the activities you’ve already completed before
            starting Pathfinder.
          </p>
          <p>
            We recommend doing this with your Strategist or SSM so that they can simply mark off the goals as “Already
            achieved.” If you’d like to do it on your own, you can mark all the goals you believe you’ve completed “For
            Review,” and your Strategist can approve them before or during your next session.
          </p>
        </div>
      );
    },
  },
  {
    title: 'How was Pathfinder created?',
    imageSrc: '/landingPage/about_05.png',
    content: () => {
      return (
        <div>
          <p>
            Pathfinder was created by the Crimson Product team in close collaboration with admissions officers at four
            top universities. After aggregating their input, we used applications from among our thousands of past
            Crimson students to calibrate the tool.
          </p>
          <p>
            We continue to conduct constant calibration using thousands of new datapoints from each application cycle.
            We also conduct an annual update to reflect changes in admissions processes, weighting, and desired traits
            over time.
          </p>
        </div>
      );
    },
  },
  {
    title: 'Where can I find more information?',
    imageSrc: '/landingPage/about_06.png',
    content: () => {
      return (
        <div>
          <p>
            The Pathfinder Student FAQ is available in the Resource Center. Click{' '}
            <a
              target="__blank"
              href="https://app.crimsoneducation.org/resource/detail/0a8179f3-bbad-4049-a82c-a3f6558ccbd3"
            >
              here
            </a>{' '}
            to go there directly.
          </p>
          <p>
            Other than that, the best way to find answers to your questions is to jump into Pathfinder! Click “Get
            Started” below to explore the 280+ goals within. If you find any you’d like to pursue, your Strategist can
            assign them to you during your next session.
          </p>
          <p>
            If you’d prefer to wait to open Pathfinder with your Strategist or SSM, don’t continue beyond this page.
          </p>
        </div>
      );
    },
  },
];

const AboutPathFinderModal = ({
  onSubmit,
  buttonGetStartedVisible,
  yearOfApplication,
}: {
  onSubmit: (values: SetOverallGoalValue) => void;
  buttonGetStartedVisible?: boolean;
  yearOfApplication?: string;
}): JSX.Element => {
  const overallGoalModalRef = useRef<ModalReturnType | null>(null);
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const openSetOverallGoalModal = () => {
    const applicationYear = yearOfApplication
      ? yearOfApplication?.split('/').length > 0
        ? yearOfApplication?.split('/')[0]
        : null
      : null;
    overallGoalModalRef.current = popupModalInfo({
      width: '500px',
      content: (
        <SetOverallGoalModal
          title="Pathfinder Settings"
          canSubmit={!!buttonGetStartedVisible}
          onSubmit={onSubmit}
          onClose={() => overallGoalModalRef.current?.destroy()}
          formFieldsData={defaultStudentFields}
          initialValues={{ applicationYear }}
        />
      ),
      className: 'component-set-overall-goal-modal',
    });
  };
  const StepsContent = (step: number) => {
    const thisStep = Steps[step];
    return (
      <div>
        <StyledTitle>{thisStep.title}</StyledTitle>
        <div>
          <StyledDisplayContainer>
            {thisStep.videoUrl && (
              <StyledVideoContainer
                title={`iframe-${thisStep.videoUrl}`}
                src={thisStep.videoUrl}
              ></StyledVideoContainer>
            )}
            {thisStep.imageSrc && (
              <>
                <StyledImageContainer>
                  <img src={thisStep.imageSrc} />
                </StyledImageContainer>
                {thisStep.content()}
              </>
            )}
          </StyledDisplayContainer>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div>{StepsContent(current)}</div>
      <StyledButtonContainer>
        <Button disabled={current === 0} onClick={() => prev()}>
          <LeftCircleOutlined />
        </Button>
        <StyledPaginationText>
          {current + 1}/{Steps.length}
        </StyledPaginationText>
        <Button disabled={current === Steps.length - 1} onClick={() => next()}>
          <RightCircleOutlined />
        </Button>
      </StyledButtonContainer>
      {current === Steps.length - 1 && buttonGetStartedVisible !== false && (
        <StyledGetStartedButton onClick={openSetOverallGoalModal} type="primary">
          Get Started
        </StyledGetStartedButton>
      )}
    </div>
  );
};
export default AboutPathFinderModal;
