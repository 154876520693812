import StudentLandingPage from 'src/components/StudentLandingPage';
import StrategistLandingPage from 'src/components/StrategistLandingPage';
import { PermissionAction, Actions } from 'src/utils/permissions';
import Layout from 'src/components/Layout';

const LandingPage = ({
  userId,
  studentId,
  setHasProgress,
  permission,
}: {
  userId: string;
  studentId: string;
  setHasProgress: (progress: boolean) => void;
  permission: PermissionAction;
}): JSX.Element => {
  if (!permission[Actions.StrategistLandingPage]) {
    const header = {
      text: 'Pathfinder',
      breadcrumbs: [],
    };
    return (
      <Layout headerProps={header}>
        <StudentLandingPage
          permission={permission}
          studentId={studentId}
          userId={userId}
          setHasProgress={setHasProgress}
        />
      </Layout>
    );
  } else {
    const header = {
      text: 'Pathfinder',
      breadcrumbs: [
        {
          text: 'Our People',
          link: () => {
            // TODO: fix this
            const domain = window.xprops && window.xprops.domain ? window.xprops.domain : 'http://localhost:3000';
            const location = `${domain}/our-people`;
            window.open(location);
          },
        },
      ],
    };
    return (
      <Layout headerProps={header}>
        <StrategistLandingPage permission={permission} studentUserId={studentId} setHasProgress={setHasProgress} />
      </Layout>
    );
  }
};

export default LandingPage;
