import { Modal, ModalFuncProps } from 'antd';
import { CloseIcon } from 'src/components/Icons';
import PermissionDenied from 'src/modals/PermissionDeniedModal';

declare type ConfigUpdate = ModalFuncProps | ((prevConfig: ModalFuncProps) => ModalFuncProps);

export type ModalReturnType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  destroy: (...args: any[]) => void;
  update: (configUpdate: ConfigUpdate) => void;
};
export const popupModalInfo = ({
  maskClosable = true,
  icon = '',
  closable = true,
  content,
  centered = true,
  className,
  okText = '',
  closeIcon = <CloseIcon />,
  ...rest
}: ModalFuncProps): ModalReturnType => {
  return Modal.info({
    maskClosable,
    icon,
    closable,
    content,
    closeIcon,
    centered,
    className,
    okText,
    ...rest,
  });
};

export const popupPermissionDeniedModalInfo = (): void => {
  popupModalInfo({
    width: '330px',
    content: <PermissionDenied />,
    closable: false,
  });
};
