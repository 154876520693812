import React, { useState, useEffect, useContext } from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorBoundary from './components/Errors/ErrorBoundary';
import { QUERY_STUDENT_OVERALL_GOAL } from 'src/graphql/StudentOverallGoal';
import { useQuery } from '@apollo/client';
import LandingPage from 'src/pages/LandingPage';
import App from './App';
import { getGraphQLClient } from './graphql';
import queryString from 'query-string';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GET_GRADE_INFO } from 'src/graphql/GoalApi';
import { ThemeProvider } from 'styled-components';
import defaultTheme from './theme';
import { ApolloClientContext } from './context/ApolloClientContext';
import { CurrentUserContext, defaultUserValue } from './context/CurrentUserContext';
import { CurrentPathfinderContext } from './context/CurrentPathfinderTypeContext';
import { User } from './types/user';
import { QUERY_USER_INFO } from './graphql/User';
import { popupPermissionDeniedModalInfo } from './utils/commonModal';
import { allowedUserRoles, isStudent } from './utils/roleUtil';
import { getPermissions, Pages } from './utils/permissions';
import * as Logger from '@crimson-education/browser-logger';
import { featureSwitches } from './featureSwitches';

const InitialProgressCheck = ({
  userId,
  pathfinderApiClient,
}: {
  userId: string;
  pathfinderApiClient: ApolloClient<NormalizedCacheObject>;
}): JSX.Element => {
  const [hasProgress, setHasProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const { refetch: queryStudentOverallGoal } = useQuery(QUERY_STUDENT_OVERALL_GOAL, {
    client: pathfinderApiClient,
    skip: true,
  });
  useQuery(GET_GRADE_INFO, {
    client: pathfinderApiClient,
    variables: { studentId: userId },
  });
  const [currentUser, setCurrentUser] = useState<User>(defaultUserValue);
  const [studentInfo, setStudentInfo] = useState<User>(defaultUserValue);
  const [pathfinderType, setPathfinderType] = useState<string>('');
  const [canEdit, setCanEdit] = useState<boolean>(true);
  const [version, setVersion] = useState<number>(1);
  const { crimsonAppApiClient } = useContext(ApolloClientContext);
  const currentUserId = window.xprops.userId;
  const { version: pathVersion } = queryString.parse(window.location.search);
  const { data: currentUserData, loading: currentUserDataLoading } = useQuery<{
    user: User;
  }>(QUERY_USER_INFO, {
    variables: {
      userId: currentUserId,
    },
    client: crimsonAppApiClient,
  });
  const {
    data: studentInfoData,
    loading: studentInfoInfoloading,
    refetch: refetchStudentInfo,
  } = useQuery<{
    user: User;
  }>(QUERY_USER_INFO, {
    variables: {
      userId,
    },
    client: crimsonAppApiClient,
  });

  useEffect(() => {
    if (!currentUserDataLoading && currentUserData?.user) {
      const currentUser = { ...currentUserData?.user, permission: getPermissions(currentUserData?.user.roles) };
      setCurrentUser(currentUser);
      if (!currentUser.roles.some(({ roleId }: { roleId: string }) => allowedUserRoles.includes(roleId))) {
        popupPermissionDeniedModalInfo();
      }
      if (isStudent(currentUser.roles)) {
        if (currentUserId !== userId) {
          popupPermissionDeniedModalInfo();
        }
      }

      const contextMetadata = {
        isAnonymous: 'false',
        userId: currentUser.userId,
      };
      Logger.addMetadata(contextMetadata);
      Logger.setUser({
        id: currentUser.userId,
        email: currentUser.email,
        username: currentUser.email,
        name: `${currentUser.firstName ?? ''} ${currentUser.lastName ?? ''}`.trim(),
      });
      if (featureSwitches.LOGGER_RECORD_SESSION()) {
        Logger.recordSession();
      }
    }
    if (!studentInfoInfoloading && studentInfoData?.user) {
      setStudentInfo(studentInfoData?.user);
    }
  }, [currentUserDataLoading, currentUserData, studentInfoData, studentInfoInfoloading, currentUserId, userId]);

  useEffect(() => {
    async function fetchUserOverallGoal() {
      setLoading(true);
      const res = await queryStudentOverallGoal({
        userId,
      });
      const data = res?.data || null;
      setLoading(false);
      if (data) {
        const { studentOverallGoal, latestVersion } = data;
        if (studentOverallGoal) {
          setHasProgress(true);
          setPathfinderType(studentOverallGoal.pathfinderType);
          if (pathVersion && studentOverallGoal?.version !== Number(pathVersion)) {
            setVersion(1);
            setCanEdit(false);
          } else {
            setVersion(studentOverallGoal.version);
            setCanEdit(true);
          }
          if (latestVersion !== studentOverallGoal.version) {
            console.log('new version available, should update');
          }
        }
      }
    }
    fetchUserOverallGoal();
  }, [queryStudentOverallGoal, userId, pathVersion]);
  return (
    <CurrentUserContext.Provider value={{ currentUser, studentInfo, refetchStudentInfo }}>
      <CurrentPathfinderContext.Provider
        value={{
          pathfinderType,
          setPathfinderType,
          version,
          setVersion,
          canEdit,
        }}
      >
        {currentUser?.userId && studentInfo?.userId && (
          <>
            {hasProgress && <App {...window.xprops} />}
            {!hasProgress && !loading && userId && (
              <LandingPage
                userId={currentUser?.userId as string}
                studentId={userId as string}
                permission={{ ...currentUser?.permission[Pages.Default] }}
                setHasProgress={setHasProgress}
              />
            )}
          </>
        )}
      </CurrentPathfinderContext.Provider>
    </CurrentUserContext.Provider>
  );
};
const Main = (props: { progressAPIUrl: string; crimsonAppAPIUrl: string }): JSX.Element => {
  const pathfinderApiClient = getGraphQLClient(
    new URL('/pathfinder-service/graphql', props.progressAPIUrl).href,
    window.xprops,
  );
  const crimsonAppApiClient = getGraphQLClient(new URL('/graphql', props.crimsonAppAPIUrl).href, window.xprops);
  const { userId } = queryString.parse(window.location.search);
  if (!userId) {
    return <div>Please input the correct user id</div>;
  }
  return (
    <ErrorBoundary>
      <React.Suspense fallback={null}>
        <ApolloClientContext.Provider
          value={{
            pathfinderApiClient,
            crimsonAppApiClient,
          }}
        >
          <ApolloProvider client={pathfinderApiClient}>
            <Router>
              <ThemeProvider theme={defaultTheme}>
                <InitialProgressCheck userId={userId as string} pathfinderApiClient={pathfinderApiClient} />
              </ThemeProvider>
            </Router>
          </ApolloProvider>
        </ApolloClientContext.Provider>
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default Main;
