import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import * as CSS from 'csstype';
import { Breadcrumb } from 'antd';
import { HeaderType } from 'src/routes/pagePropsTypes';

const StyledBreadcrumb = styled(Breadcrumb)`
  @media (max-width: ${(props) => props.theme.breakPoints.xm}) {
    display: none;
  }
  color: #ffffff;
  .ant-breadcrumb-separator {
    height: 15px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    color: #f6f7fa;
  }
  .ant-breadcrumb-link {
    height: 15px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    color: #f6f7fa;
    cursor: pointer;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  background-color: #464ac9;
  color: #ffffff;
  font-family: Montserrat;
  @media (min-width: ${(props) => props.theme.breakPoints.xm}) {
    height: 100px;
    padding: 24px 0px 24px 70px;
    font-size: 24px;
    font-weight: 500;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.xm}) {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 16px;
  }
`;

const HeaderText = styled.div`
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  @media (min-width: ${(props) => props.theme.breakPoints.xm}) {
    font-size: 24px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.xm}) {
    font-size: 16px;
    display: flex;
    flex: 1;
    justify-content: center;
  }
`;

const CommonHeader = ({
  style,
  otherProps,
}: {
  style: CSS.Properties | undefined;
  otherProps: HeaderType;
}): JSX.Element => {
  const { text, breadcrumbs, leftIconMobile, rightIconMobile } = otherProps;
  const history = useHistory();
  return (
    <HeaderContainer style={style}>
      {breadcrumbs.length > 0 && (
        <StyledBreadcrumb separator=">">
          {breadcrumbs.map((item) => {
            return (
              <StyledBreadcrumb.Item
                key={item.text}
                onClick={() => (typeof item.link === 'function' ? item.link() : history.push(item.link))}
              >
                {item.text}
              </StyledBreadcrumb.Item>
            );
          })}
        </StyledBreadcrumb>
      )}
      {leftIconMobile && leftIconMobile}
      <HeaderText> {text}</HeaderText>
      {rightIconMobile && rightIconMobile}
    </HeaderContainer>
  );
};

export default CommonHeader;
