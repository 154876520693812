import { MouseEventHandler, useEffect, useState } from 'react';
import { FieldConfig, Form, ValidateStatus } from 'src/components/Form';
import styled from 'styled-components';
import { Level, PathfinderType } from 'src/__generated__/graphqlTypes';
import moment from 'moment';
import {
  defaultFields,
  editFormFields,
  getSelectableYearList,
  targetLevelOptionsUS,
  targetLevelOptionsUK,
  targetLevelOptionUS_UK,
} from './formFields';
import theme from '../../theme';
import './index.less';

export type SetOverallGoalValue = {
  targetLevel: Level;
  startDate: string;
  applicationYear: string;
  pathfinderType: PathfinderType;
};

export type initialValuesType = {
  targetLevel?: Level;
  startDate?: string;
  applicationYear?: string | null;
  pathfinderType?: PathfinderType;
};
type Props = {
  title: string;
  onClose: MouseEventHandler<HTMLElement>;
  onSubmit: (values: SetOverallGoalValue) => void;
  editMode?: boolean;
  currentUserIsStudent?: boolean;
  canSubmit: boolean;
  initialValues?: initialValuesType;
  formFieldsData?: FieldConfig[];
};

export const StyledTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 32px;
  margin-top: 32px;
  @media (max-width: ${theme.breakPoints.xm}) {
    font-size: 20px;
  }
`;

export const ModalWrapper = styled.div`
  margin: 16px 0;
  padding: 0 44px;
  @media (max-width: ${theme.breakPoints.xm}) {
    padding: 0;
  }
`;

const SetOverallGoalModal = ({
  title,
  onClose,
  onSubmit,
  initialValues,
  editMode = false,
  currentUserIsStudent = false,
  canSubmit = false,
  formFieldsData = defaultFields,
}: Props): JSX.Element => {
  const [formFields, setFormFields] = useState<FieldConfig[]>(formFieldsData);
  useEffect(() => {
    if (editMode) {
      let finalEditFormFields = currentUserIsStudent ? editFormFields['student'] : editFormFields['default'];
      if (initialValues) {
        const { applicationYear, startDate, pathfinderType } = initialValues;
        if (!applicationYear || !startDate || !pathfinderType) {
          return;
        }
        const startDateLimit = generateStartDateLimit(applicationYear);
        const applicationYearOptions = generateApplicationYearOptions(startDate);
        finalEditFormFields = finalEditFormFields.map((field) => {
          if (field.name === 'startDate') {
            return {
              ...field,
              disabledDate: (current: moment.Moment) => {
                return current && current > startDateLimit;
              },
            };
          }
          if (field.name === 'applicationYear') {
            return {
              ...field,
              options: applicationYearOptions,
            };
          }
          if (field.name === 'targetLevel') {
            const options =
              pathfinderType === 'UK'
                ? targetLevelOptionsUK
                : pathfinderType === 'US'
                ? targetLevelOptionsUS
                : targetLevelOptionUS_UK;
            return {
              ...field,
              options,
            };
          }
          return field;
        });
      }
      setFormFields(finalEditFormFields);
    }
  }, [editMode, currentUserIsStudent, initialValues]);
  const generateApplicationYearOptions = (startDate: string) => {
    let applicationStartYear = null;
    const currentYear = new Date(startDate).getFullYear();
    if (moment(startDate) > moment(new Date(currentYear, 8, 1))) {
      applicationStartYear = currentYear + 1;
    } else {
      applicationStartYear = currentYear;
    }
    const applicationYearOptions = getSelectableYearList(10, 1, applicationStartYear).reverse();
    return applicationYearOptions;
  };
  const generateStartDateLimit = (applicationYear: string) => {
    return moment(new Date(Number(applicationYear), 8, 1));
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFieldsChange = (changedFields: any[], fields: any[]) => {
    const field = changedFields[0];
    const { name, value } = field;
    const fieldName = name[0];
    if (fieldName === 'applicationYear') {
      const applicationYear = value;
      const startDateLimit = generateStartDateLimit(applicationYear);
      const startDateField = fields.find((field) => field.name[0] === 'startDate');
      let validateStatus: ValidateStatus;
      if (startDateField.value) {
        const startDateValue = startDateField.value;
        if (moment(startDateValue) > moment(new Date(applicationYear, 8, 1))) {
          validateStatus = 'error';
        }
      }
      const newFormField = formFields.map((field) => {
        if (field.name === 'startDate') {
          return {
            ...field,
            disabledDate: (current: moment.Moment) => {
              return current && current > startDateLimit;
            },
          };
        }
        if (field.name === 'applicationYear') {
          return {
            ...field,
            validateStatus,
            help: validateStatus === 'error' ? 'The application year should be greater than the start date' : '',
            disabledDate: (current: moment.Moment) => {
              return current && current > startDateLimit;
            },
          };
        }
        return field;
      });
      setFormFields(newFormField);
    }
    if (fieldName === 'startDate') {
      const startDate = value;
      const applicationYearOptions = generateApplicationYearOptions(startDate);
      const applicationYearField = fields.find((field) => field.name[0] === 'applicationYear');
      let validateStatus: ValidateStatus;
      if (applicationYearField.value) {
        const applicationYear = applicationYearField.value;
        if (moment(startDate) > moment(new Date(applicationYear, 8, 1))) {
          validateStatus = 'error';
        }
      }
      const newFormField = formFields.map((field) => {
        if (field.name === 'applicationYear') {
          return {
            ...field,
            options: applicationYearOptions,
            validateStatus,
            help: validateStatus === 'error' ? 'The application year should be greater than the start date' : '',
          };
        }
        return field;
      });
      setFormFields(newFormField);
    }
    if (fieldName === 'pathfinderType') {
      const options =
        value === 'UK' ? targetLevelOptionsUK : value === 'US' ? targetLevelOptionsUS : targetLevelOptionUS_UK;
      setFormFields(
        formFields.map((field) =>
          field.name === 'targetLevel'
            ? {
                ...field,
                options: [...options],
              }
            : field,
        ),
      );
    }
  };
  const onSubmitForm = (values: SetOverallGoalValue) => {
    //validate rules
    const { startDate, applicationYear } = values;
    if (moment(startDate) > moment(new Date(Number(applicationYear), 8, 1))) {
      return;
    } else {
      onSubmit(values);
    }
  };
  return (
    <ModalWrapper>
      <StyledTitle>{title}</StyledTitle>
      <Form
        layout="vertical"
        name="set-overall-goal-form"
        fields={formFields}
        onSubmit={onSubmitForm}
        onCancel={onClose}
        submit={canSubmit ? 'Save' : false}
        initialValues={initialValues}
        onFieldsChange={onFieldsChange}
      />
    </ModalWrapper>
  );
};

export default SetOverallGoalModal;
