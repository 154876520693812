import { Select as AntSelect } from 'antd';
import { ExpandMoreIcon } from 'src/components/Icons';
import { SelectOption } from './types';
const { Option } = AntSelect;
import './index.less';

type Props = {
  showSearch?: boolean;
  options: SelectOption[];
  dropdownClassName?: string;
  onChange?: (value: string) => void;
};
const Select = ({ options, onChange, ...props }: Props): JSX.Element => {
  return (
    <AntSelect suffixIcon={<ExpandMoreIcon />} onChange={onChange} {...props}>
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </AntSelect>
  );
};

export default Select;
