import { Close } from '@styled-icons/material';
import styled from 'styled-components';

type Props = {
  width?: number;
  height?: number;
};

const CloseIcon = ({ width = 20, height = 20 }: Props): JSX.Element => {
  const Icon = styled(Close)`
    width: ${width}px;
    height: ${height}px;
    color: #1d1e2b;
  `;
  return <Icon />;
};

export default CloseIcon;
