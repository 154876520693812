import styled from 'styled-components';
import { Button } from 'antd';

export const StyledContainer = styled.div`
  width: 100%;
  background: white;
  height: 100vh;
`;

export const StyledMainContentBox = styled.div`
  width: 888px;
  margin: 130px auto 0;
  display: flex;
`;

export const StyledLeftImageBox = styled.div`
  width: 346px;
  height: 252px;
  margin-right: 112px;
  & img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledRightContentBox = styled.div`
  width: 432px;
  color: #1d1e2b;
`;

export const StyledRightContentTitle = styled.div`
  font-family: Montserrat-Bold;
  font-size: 20px;
  margin-bottom: 30px;
  text-align: center;
`;

export const StyledRightContentParagraph = styled.p`
  font-size: 14px;
`;

export const StyledGetStartedButton = styled(Button)`
  width: 183px;
  height: 40px;
  border-radius: 50px;
`;

export const StyledButtonArea = styled.div`
  margin-top: 40px;
  text-align: center;
`;
