import { ApolloQueryResult, OperationVariables } from '@apollo/client/core/types';
import { createContext } from 'react';
import { User } from 'src/types/user';

export const CurrentUserContext = createContext<
  | {
      currentUser: User;
      studentInfo: User;
      refetchStudentInfo: (variables?: Partial<OperationVariables> | undefined) => Promise<
        ApolloQueryResult<{
          user: User;
        }>
      >;
    }
  | Record<string, never>
>({});

export const defaultUserValue = {
  userId: '',
  fullName: '',
  firstName: '',
  lastName: '',
  email: '',
  country: '',
  profileImageUrl: '',
  studentInfo: {
    schoolYearLevel: '',
    yearOfApplication: '',
  },
  roles: [],
  permission: {},
};
