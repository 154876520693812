import { useRef, useEffect, useContext } from 'react';
import { popupModalInfo, ModalReturnType } from 'src/utils/commonModal';
import UserInfoQuery from 'src/components/UserInfoQuery';
import { useHistory } from 'react-router-dom';
import SetOverallGoalModal, { SetOverallGoalValue } from 'src/modals/SetOverallGoalModal';
import { allRoutePathGen } from 'src/routes';
import { CREATE_STUDENT_OVERALL_GOAL } from 'src/graphql/StudentOverallGoal';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { PermissionAction, Actions } from 'src/utils/permissions';
import { UPDATE_YEAR_OF_APPLICATION } from 'src/graphql/User';
import { ApolloClientContext } from 'src/context/ApolloClientContext';
import { CurrentPathfinderContext } from 'src/context/CurrentPathfinderTypeContext';
import SetStartPointModal from '../SetStartPointModal';
import {
  StyledMainContentBox,
  StyledLeftImageBox,
  StyledRightContentBox,
  StyledRightContentTitle,
  StyledRightContentParagraph,
  StyledGetStartedButton,
  StyledButtonArea,
  StyledContainer,
} from './style';

const StrategistLandingPage = ({
  studentUserId,
  setHasProgress,
  permission,
}: {
  studentUserId: string;
  setHasProgress: (progress: boolean) => void;
  permission: PermissionAction;
}): JSX.Element => {
  const { studentInfo } = useContext(CurrentUserContext);
  const [submitStudentOverallGoal, { data: submitStudentOverallGoalResult, loading: submitStudentOverallGoalLoading }] =
    useMutation(CREATE_STUDENT_OVERALL_GOAL);
  const { crimsonAppApiClient } = useContext(ApolloClientContext);
  const [updateYearOfApplication] = useMutation(UPDATE_YEAR_OF_APPLICATION, { client: crimsonAppApiClient });
  const yearOfApplication = studentInfo.studentInfo.yearOfApplication;
  const { canEdit } = useContext(CurrentPathfinderContext);
  const history = useHistory();
  const overallGoalModalRef = useRef<ModalReturnType | null>(null);
  const startPointModalRef = useRef<ModalReturnType | null>(null);
  useEffect(() => {
    if (!submitStudentOverallGoalLoading && submitStudentOverallGoalResult) {
      overallGoalModalRef.current?.destroy();
      const onCloseStartPointModal = () => {
        startPointModalRef.current?.destroy();
        setHasProgress(true);
        history.push(allRoutePathGen.presettings);
      };
      startPointModalRef.current = popupModalInfo({
        width: '532px',
        content: <SetStartPointModal onOk={onCloseStartPointModal} />,
        onCancel: onCloseStartPointModal,
        className: 'component-set-start-point-modal',
      });
    }
  }, [submitStudentOverallGoalResult, submitStudentOverallGoalLoading, setHasProgress, history]);

  const onSubmit = (values: SetOverallGoalValue) => {
    const { targetLevel, startDate, applicationYear, pathfinderType } = values;
    if (yearOfApplication !== applicationYear) {
      updateYearOfApplication({
        variables: {
          userId: studentUserId as string,
          yearOfApplication: applicationYear + '/' + (Number(applicationYear) + 1),
        },
      });
    }
    submitStudentOverallGoal({
      variables: {
        userId: studentUserId as string,
        targetLevel,
        startDate: moment(startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format(),
        applicationYear: Number(applicationYear),
        isSelfInit: false,
        pathfinderType,
      },
    });
  };

  const openSetOverallGoalModal = () => {
    const applicationYear = yearOfApplication?.split('/').length > 0 ? yearOfApplication?.split('/')[0] : null;
    overallGoalModalRef.current = popupModalInfo({
      width: '500px',
      content: (
        <SetOverallGoalModal
          title="Pathfinder Settings"
          canSubmit={true}
          onSubmit={onSubmit}
          onClose={() => overallGoalModalRef.current?.destroy()}
          initialValues={{ applicationYear }}
        />
      ),
      className: 'component-set-overall-goal-modal',
    });
  };
  const { fullName: studentName } = studentInfo;
  return (
    <StyledContainer>
      <UserInfoQuery />
      <StyledMainContentBox>
        <StyledLeftImageBox>
          <img src="/startTheJourney.png" />
        </StyledLeftImageBox>
        <StyledRightContentBox>
          <StyledRightContentTitle>Start the journey</StyledRightContentTitle>
          <StyledRightContentParagraph>
            {studentName} hasn’t begun their Pathfinder yet. You can initialize Pathfinder for them by clicking “Get
            started” below.
          </StyledRightContentParagraph>
          <StyledRightContentParagraph>
            We recommend you do so during a session, so that you can choose goals together and check off the ones{' '}
            {studentName} has already completed.
          </StyledRightContentParagraph>
          <StyledButtonArea>
            <StyledGetStartedButton
              disabled={!permission[Actions.Initialize] || !canEdit}
              type="primary"
              onClick={openSetOverallGoalModal}
            >
              Get Started
            </StyledGetStartedButton>
          </StyledButtonArea>
        </StyledRightContentBox>
      </StyledMainContentBox>
    </StyledContainer>
  );
};
export default StrategistLandingPage;
