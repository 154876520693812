import ReactDOM from 'react-dom';
import * as zoid from 'zoid/dist/zoid.frameworks';
import { getConfig } from './config';
import Main from './main';
import * as Logger from '@crimson-education/browser-logger';

const config = getConfig(window.process.env);

zoid.create({
  // The html tag used to render my component
  tag: 'pathfinder-component',
  // The url that will be loaded in the iframe or popup, when someone includes my component on their page
  url: config.domain,

  props: {
    token: {
      type: 'string',
      isRequired: true,
    },
    userId: {
      type: 'string',
      isRequired: true,
    },
    authorize: {
      type: 'function',
      isRequired: false,
    },
    getBearer: {
      type: 'function',
      isRequired: false,
    },
  },
});

const isLocalhost = window.location.hostname === 'localhost';
Logger.init({
  service: 'pathfinder-frontend',
  environment: config.environment,
  version: config.version,
  defaultMetadata: {
    application: 'core-progress',
  },

  reporters: {
    log: true,
    datadog: config.datadogApplicationId
      ? {
          applicationId: config.datadogApplicationId,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          clientToken: config.datadogClientToken!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          site: config.datadogSite!,
          proxyUrl: 'https://proxy.crimsoneducation.org/ddog',
          sampleRate: 50,
          replaySampleRate: 50,
          useSecureSessionCookie: !isLocalhost,
          useCrossSiteSessionCookie: !isLocalhost,
          trackInteractions: true,
          forwardConsoleLogs: true,
          allowedTracingOrigins: [config.progressAPIUrl, config.crimsonAppAPIUrl],
        }
      : undefined,

    amplify: config.pinpointAnalyticsAppId
      ? {
          region: config.awsRegion,
          identityPoolId: config.pinpointIdentityPoolId,
          analyticsAppId: config.pinpointAnalyticsAppId,
          proxyUrl: config.pinpointProxyUrl,
          autoTrackPageViews: true,
          autoTrackEvents: true,
          autoTrackSessions: true,
        }
      : undefined,
  },
});

if (config.datadogApplicationId) {
  console.debug('Pathfinder Datadog enabled');
}

ReactDOM.render(
  <Main progressAPIUrl={config.progressAPIUrl} crimsonAppAPIUrl={config.crimsonAppAPIUrl} />,
  document.getElementById('root'),
);
