import CRAvatar from 'src/molecules/Avatar';
import { CRDropDown, DropDownOverlay } from 'src/components/DropDown';
import styled from 'styled-components';
import { HEAD_TUTOR } from 'src/utils/roles.json';
import { useContext } from 'react';
import { CurrentUserContext } from 'src/context/CurrentUserContext';
import { CurrentPathfinderContext } from 'src/context/CurrentPathfinderTypeContext';
import { isHeadTutor } from 'src/utils/roleUtil';
import { StyledUserInfoContainer, StyledUserInfoBox, StyledInfoTextBox, StyledUserName, StyledGreyText } from './style';
type UserInfoProps = {
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  userId: string;
  profileImageUrl?: string;
  applicationYear?: number;
  grade?: number;
  style?: React.CSSProperties;
};
const SubTabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 41px;
  & button {
    width: 206px;
    border: 1px solid #464ac9;
    border-radius: 50px;
    color: #464ac9;
    background: transparent;
    padding-left: 37px;
    padding-right: 24px;
  }
`;

const tabLinksByRoles = {
  Other: [
    'Profile',
    'Crimson Programs',
    'Program Usage',
    'Session History',
    'ECL',
    'Roadmap',
    'Tasks',
    'Grades',
    'Crimson Testing',
    'Applications',
    'Reports',
  ],
  [HEAD_TUTOR]: ['Profile', 'Program Usage', 'Tasks', 'Grades', 'Crimson Testing'],
};
const dropdownListMapping: { [key: string]: string } = {
  Profile: 'profile',
  'Crimson Programs': 'package',
  'Program Usage': 'subjects',
  'Session History': 'otherSessions',
  ECL: 'ecl',
  Roadmap: 'roadmap',
  Tasks: 'tasks',
  Grades: 'grades',
  'Crimson Testing': 'test-prep',
  Applications: 'applications',
  Reports: 'reports',
};
const UserInfo = ({
  fullName,
  firstName,
  lastName,
  email,
  profileImageUrl,
  applicationYear,
  grade,
  userId,
  style,
}: UserInfoProps): JSX.Element => {
  const { currentUser } = useContext(CurrentUserContext);
  const { canEdit } = useContext(CurrentPathfinderContext);
  const { roles } = currentUser;
  let dropDownList = [];
  if (isHeadTutor(roles)) {
    dropDownList = tabLinksByRoles[HEAD_TUTOR];
  } else {
    dropDownList = tabLinksByRoles['Other'];
  }
  const setDropDownValue = () => {
    //TODO: unnecessary func
    console.log('setDropDownValue: ', setDropDownValue);
  };
  return (
    <StyledUserInfoContainer style={style}>
      <StyledUserInfoBox>
        <CRAvatar size={60} image={profileImageUrl} firstName={firstName} lastName={lastName} userId={userId} />
        <StyledInfoTextBox>
          <StyledUserName>{fullName}</StyledUserName>
          <StyledGreyText>
            {applicationYear && <span>App year {applicationYear}</span>}
            {grade && <span>(Grade {grade})</span>}
          </StyledGreyText>
          <StyledGreyText>{email}</StyledGreyText>
        </StyledInfoTextBox>
      </StyledUserInfoBox>
      <SubTabContainer>
        {canEdit && (
          <CRDropDown
            value="Pathfinder"
            overlay={
              <DropDownOverlay
                dropDownList={dropDownList}
                currentValue="Pathfinder"
                setCurrentValue={setDropDownValue}
                onChange={(value: string) => {
                  const link = dropdownListMapping[value];
                  const domain = window.xprops && window.xprops.domain ? window.xprops.domain : 'http://localhost:3000';
                  const location = `${domain}/users/${userId}/${link}`;
                  window.open(location);
                }}
              />
            }
          />
        )}
      </SubTabContainer>
    </StyledUserInfoContainer>
  );
};
export default UserInfo;
