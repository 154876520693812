export const theme: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  breakPoints: {
    sm: '1440px',
    xm: '768px',
    xs: '375px',
  },
  common: {
    darkNavy: '#1d1e2b',
    stone: '#73747d',
    coolGrey: '#a9acc0',
    paleGrey: '#e3e7ed',
    mist: '#f3f6fa',
    indigo: '#464ac9',
    salmon: '#ed4b53',
    orange: '#ff764c',
    yellow: '#fdaa02',
    lightGray: '#f6f7fa',
    cyan: '#12c39a',
  },
  avatar: {
    avatar0: '#ca0915',
    avatar1: '#ff5f00',
    avatar2: '#cda132',
    avatar3: '#b5ba1b',
    avatar4: '#64a324',
    avatar5: '#157112',
    avatar6: '#17b0a0',
    avatar7: '#1271ac',
    avatar8: '#1e4c77',
    avatar9: '#1b2387',
    avatar10: '#9b19ff',
    avatar11: '#620ba6',
    avatar12: '#d30f8e',
    avatar13: '#860759',
  },
};

export default theme;

export type ThemeType = typeof theme;
