import { gql } from '@apollo/client';

export const GET_GRADE_INFO = gql`
  query getGradeInfo($studentId: String!) {
    getGradeInfo(studentId: $studentId) {
      success
    }
  }
`;

export const GET_GOALS = gql`
  query getGoals(
    $studentId: String!
    $pathfinderType: String
    $version: Int!
    $where: GoalCardWhereInput!
    $orderBy: [OrderBy!]
  ) {
    goalCardConnection(
      studentId: $studentId
      pathfinderType: $pathfinderType
      version: $version
      where: $where
      first: 100
      orderBy: $orderBy
    ) {
      totalCount
      nodes {
        id
        category
        v2category
        assignStatus
        targetLevel
        achievedLevel
        targetLevelPoints
        achievedPoints
        possiblePoints
        assignedPoints
        reviewPoints
        subcategory
        v2subcategory
        title
        description
        integrationType
        reviewLevel
        nextLevel
        nextLevelPoints
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_GOAL = gql`
  query ($goalId: String!, $studentId: String!) {
    goalCard(goalCardId: $goalId, studentId: $studentId) {
      id
      description
      category
      title
      subcategory
      assignStatus
      integrationType
      note
    }
  }
`;

export const GET_CARD_LEVELS = gql`
  query goalCardLevels($goalCardId: String!, $studentId: String!) {
    goalCardLevels(goalCardId: $goalCardId, studentId: $studentId) {
      id
      description
      level
      points
      status
      isTarget
    }
  }
`;

export const CLOSE_GOAL = gql`
  mutation close($input: CloseInput!) {
    closeGoal(input: $input) {
      success
    }
  }
`;

export const ASSIGN_GOAL = gql`
  mutation assignGoal($input: AssignGoalInput!) {
    assignGoal(input: $input) {
      success
    }
  }
`;

export const APPROVE_GOAL = gql`
  mutation approve($input: ApproveInput!) {
    approveGoal(input: $input) {
      success
    }
  }
`;

export const REJECT_GOAL = gql`
  mutation reject($input: RejectInput!) {
    rejectGoal(input: $input) {
      success
    }
  }
`;

export const SUBMIT_FOR_REVIEW = gql`
  mutation submitGoal($input: SubmitForReviewInput!) {
    submitGoal(input: $input) {
      success
    }
  }
`;

export const COMPLETED_BEFORE_START = gql`
  mutation completeBeforeStartGoal($input: CompleteBeforeStartInput!) {
    completeBeforeStartGoal(input: $input) {
      success
    }
  }
`;

export const MARK_ACHIEVED = gql`
  mutation markAchieveGoal($input: MarkAchieveInput!) {
    markAchieveGoal(input: $input) {
      success
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation updateNote($input: NoteInput!) {
    updateNote(input: $input) {
      note
    }
  }
`;
export const RESET_GOAL = gql`
  mutation reset($input: ResetInput!) {
    resetGoal(input: $input) {
      success
    }
  }
`;
