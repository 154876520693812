import styled from 'styled-components';

export const StyledUserInfoContainer = styled.div`
  padding: 30px 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f6fa;
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    padding-left: 81px;
    padding-right: 53px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.sm}) {
    padding: '30px 0px';
  }
`;
export const StyledUserInfoBox = styled.div`
  display: flex;
`;
export const StyledInfoTextBox = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledUserName = styled.div`
  font-family: Montserrat-Bold;
  font-size: 18px;
  color: #1d1e2b;
`;

export const StyledGreyText = styled.div`
  font-size: 12px;
  color: #a9acc0;
`;
