import styled from 'styled-components';
import { Button } from 'antd';

export const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url('/landingPage/background.png');
  @media (max-width: ${(props) => props.theme.breakPoints.xm}) {
    height: 100%;
  }
  background-size: cover;
`;

export const StyledHeader = styled.header`
  font-family: Montserrat-Bold;
  font-size: 36px;
  max-width: 718px;
  margin: 0 auto;
  padding-top: 112px;
  text-align: center;
  color: #fff;
  position: relative;
  @media (max-width: ${(props) => props.theme.breakPoints.xm}) {
    font-size: 22px;
    max-width: 277px;
    padding-top: 51px;
  }
`;

export const StyledIntro = styled.p`
  font-size: 16px;
  color: #fff;
  max-width: 633px;
  margin: 40px auto 0;
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakPoints.xm}) {
    max-width: 302px;
    color: #aaacbe;
    font-size: 14px;
  }
`;

export const StyledIGotIn = styled.div`
  position: absolute;
  width: 136px;
  height: 75px;
  background-image: url('/landingPage/i_got_in.svg');
  background-size: cover;
  right: -60px;
  top: 42px;
  @media (max-width: ${(props) => props.theme.breakPoints.xm}) {
    display: none;
  }
`;
// TODO: self initialization:replace the image with a video
export const StyledCenteredImage = styled.div`
  width: 425px;
  height: 280px;
  background-image: url('/landingPage/pathfinder_visual.svg');
  background-size: cover;
  margin: 40px auto 0;
  @media (max-width: ${(props) => props.theme.breakPoints.xm}) {
    width: 306px;
    height: 200px;
    margin-top: 28px;
  }
`;

export const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  @media (max-width: ${(props) => props.theme.breakPoints.xm}) {
    margin-top: 38px;
    margin-bottom: 118px;
  }
`;

export const StyledGetStartedButton = styled(Button)`
  width: 220px;
  height: 40px;
  border-radius: 50px;
  @media (max-width: ${(props) => props.theme.breakPoints.xm}) {
    width: 253px;
  }
`;

export const StyledAboutButton = styled(Button)`
  width: 220px;
  height: 40px;
  border-radius: 50px;
  color: white;
  background: transparent;
  margin-right: 10px;
  @media (max-width: ${(props) => props.theme.breakPoints.xm}) {
    width: 253px;
    margin-right: 0px;
    margin-top: 20px;
  }
`;
