import { Form as AntForm } from 'antd';
import { Rule } from 'antd/lib/form';
import { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { StyledButtonFilled, StyledButtonOutlined } from '../Buttons';
import { DatePicker, Input, InputNumber, Select, SelectOption } from '../Fields';
import './style.less';
import theme from '../../theme';

export type ValidateStatus = 'success' | 'warning' | 'error' | 'validating';
type CommonFieldConfig = {
  type: 'input' | 'select' | 'datepicker' | 'number';
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  rules?: Rule[];
  validateStatus?: ValidateStatus;
  help?: string;
};
export type FieldConfig = CommonFieldConfig &
  (
    | { type: 'input' }
    | {
        type: 'datepicker';
        format?: string;
        dropdownClassName?: string;
        disabledDate?: (current: moment.Moment) => boolean;
      }
    | {
        type: 'select';
        options: SelectOption[];
        placeholder?: string;
        defaultValue?: string;
        showSearch?: boolean;
        dropdownClassName?: string;
        optionFilterProp?: string;
        filterOption?: (input: string, option: { children: string }) => boolean;
      }
    | {
        type: 'number';
        min?: number;
        max?: number;
      }
  );

type FormConfig = {
  name: string;
  fields: FieldConfig[];
  layout?: 'vertical' | 'horizontal';
  submit?: boolean | string;
  cancel?: boolean | string;
  // TODO: Use Generics for inferred field values to avoid <any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (values: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFieldsChange?: (changedFields: any, fields: any) => void;
  onCancel?: MouseEventHandler<HTMLElement>;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Field = (field: FieldConfig) => {
  switch (field.type) {
    case 'input':
      return <Input {...field} />;
    case 'select':
      return <Select {...field} options={field.options} />;
    case 'datepicker':
      return <DatePicker {...field} />;
    case 'number':
      return <InputNumber {...field} />;
  }
};

export const CancelButton = styled(StyledButtonOutlined)`
  padding: 20px;
  @media (max-width: ${theme.breakPoints.xm}) {
    width: ${(props) => (props?.width ? `${props.width * 0.9}px` : 'auto')};
  }
`;
export const SubmitButton = styled(StyledButtonFilled)`
  padding: 20px;
  @media (max-width: ${theme.breakPoints.xm}) {
    width: ${(props) => (props?.width ? `${props.width * 0.9}px` : 'auto')};
  }
`;

export const StyledButtonContainer = styled.div`
  margin: 48px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-evenly;
  align-items: center;
`;

const Form = ({
  name,
  fields,
  layout,
  cancel,
  submit,
  onCancel,
  initialValues,
  onFieldsChange,
  onSubmit,
}: FormConfig): JSX.Element => {
  return (
    <AntForm
      layout={layout}
      name={name}
      initialValues={initialValues}
      onFieldsChange={onFieldsChange}
      onFinish={onSubmit}
      requiredMark={false}
    >
      {fields.map(({ label, ...field }, index) => (
        <AntForm.Item
          key={index}
          label={label}
          name={field.name}
          rules={field.rules}
          validateStatus={field.validateStatus}
          help={field.help}
        >
          <Field {...field} />
        </AntForm.Item>
      ))}
      <StyledButtonContainer>
        <AntForm.Item>
          {(cancel === undefined || cancel) && (
            <CancelButton onClick={onCancel} width={150}>
              {typeof cancel === 'string' ? cancel : 'Cancel'}
            </CancelButton>
          )}
        </AntForm.Item>
        <AntForm.Item>
          {(submit === undefined || submit) && (
            <SubmitButton type="primary" htmlType="submit" width={150}>
              {typeof submit === 'string' ? submit : 'Submit'}
            </SubmitButton>
          )}
        </AntForm.Item>
      </StyledButtonContainer>
    </AntForm>
  );
};

export default Form;
