import { gql } from '@apollo/client';

export const USER_INFO_FRAGMENT = gql`
  fragment userInfo on userType {
    userId
    fullName
    firstName
    lastName
    email
    profileImageUrl
    country
    isTest
    studentInfo {
      schoolYearLevel
      yearOfApplication
    }
    roles {
      roleId
      isPrimary
    }
  }
`;

export const QUERY_USER_INFO = gql`
  query userById($userId: String!) {
    user: user(userId: $userId) {
      ...userInfo
    }
  }
  ${USER_INFO_FRAGMENT}
`;

export const UPDATE_YEAR_OF_APPLICATION = gql`
  mutation updateYearOfApplication($userId: String!, $yearOfApplication: String!) {
    updateYearOfApplication(userId: $userId, yearOfApplication: $yearOfApplication)
  }
`;
