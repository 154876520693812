import { SelectOption } from 'src/components/Fields';
import { Level } from 'src/__generated__/graphqlTypes';
import { FieldConfig } from 'src/components/Form';
import moment from 'moment';

export const targetLevelOptionsUS: SelectOption[] = [
  {
    label: 'Seeker (schools ranked below top 50)',
    value: Level.Seeker,
  },
  {
    value: Level.Venturer,
    label: 'Venturer (schools ranked 31-50)',
  },
  {
    value: Level.Voyager,
    label: 'Voyager (schools ranked 15-30)',
  },
  {
    value: Level.Globetrotter,
    label: 'Globetrotter (top 15 schools, including Ivies)',
  },
];

export const targetLevelOptionUS_UK: SelectOption[] = [
  {
    label: 'Seeker (schools ranked below top 50)',
    value: Level.Seeker,
  },
  {
    value: Level.Venturer,
    label: 'Venturer (schools ranked 31-50)',
  },
  {
    value: Level.Voyager,
    label: 'Voyager (schools ranked 15-30)',
  },
  {
    value: Level.Globetrotter,
    label: 'Globetrotter (top 15 schools, including Ivies)',
  },
];

export const targetLevelOptionsUK: SelectOption[] = [
  {
    label: 'Seeker (Non Russell Groups, Foundation Year Courses, Other EU universities)',
    value: Level.Seeker,
  },
  {
    value: Level.Venturer,
    label: 'Venturer (Other 11-24 Russell Groups for UK, Top 21-30 EU)',
  },
  {
    value: Level.Voyager,
    label: 'Voyager (Top 10 Russell Groups, Top 11-20 for EU)',
  },
  {
    value: Level.Globetrotter,
    label: 'Globetrotter (Oxbridge + G5 for UK, Medicine for UK+EU, Top 10 schools for EU)',
  },
];

export function getSelectableYearList(maxYearOffset: number, minYearOffset = 60, startYear?: number): SelectOption[] {
  const CURRENT_YEAR = startYear ? startYear : new Date().getFullYear();
  const MAX_YEAR = CURRENT_YEAR + (maxYearOffset || 10);
  const MIN_YEAR = CURRENT_YEAR - minYearOffset;
  const years = [...Array(MAX_YEAR - MIN_YEAR)];

  return years.map((_, index) => {
    return {
      value: `${MAX_YEAR - index}`,
      label: `${MAX_YEAR - index}/${MAX_YEAR - index + 1}`,
    };
  });
}
const applicationYearOptions: SelectOption[] = getSelectableYearList(10, 1, 2018).reverse();

const pathfinderTypeOptions: SelectOption[] = [
  { value: 'US', label: 'US Only' },
  { value: 'US_UK', label: 'US and UK/EU' },
  { value: 'UK', label: 'UK/EU Only' },
];

const defaultStudentFields: FieldConfig[] = [
  {
    type: 'select',
    name: 'pathfinderType',
    label: 'Pathfinder Type',
    options: pathfinderTypeOptions,
    placeholder: 'Select type',
    rules: [
      {
        required: true,
        message: 'Please select the Pathfinder type',
      },
    ],
  },
  {
    type: 'datepicker',
    name: 'startDate',
    format: 'D MMM YYYY',
    label: 'Start Date',
    dropdownClassName: 'cr-datepicker-dropdown',
    rules: [
      {
        required: true,
        message: 'Please select the start date',
      },
    ],
  },
  {
    type: 'select',
    name: 'applicationYear',
    label: 'Application Year',
    options: applicationYearOptions,
    placeholder: `${moment().add(2, 'year').year()}/${moment().add(2, 'year').year() + 1}`,
    rules: [
      {
        required: true,
        message: 'Please select the application year',
      },
    ],
  },
];

const defaultFields: FieldConfig[] = (() => {
  const fields = [...defaultStudentFields];
  fields.splice(1, 0, {
    type: 'select',
    name: 'targetLevel',
    label: 'Level you’re aiming for',
    options: targetLevelOptionsUS,
    placeholder: 'Voyager',
    dropdownClassName: 'target-level',
    rules: [
      {
        required: true,
        message: 'Please select the level',
      },
    ],
  });
  return fields;
})();

const editFormFields = {
  default: defaultFields,
  student: defaultFields.map((field) =>
    field.name === 'startDate'
      ? {
          ...field,
          disabled: true,
        }
      : field,
  ),
};

export { defaultFields, editFormFields, defaultStudentFields };
