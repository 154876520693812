import { Menu, Dropdown, Button, DropDownProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const StyledDropDownContainer = styled.div<{ width?: string | undefined }>`
  width: ${(props) => props.width || '200px'};
`;

const StyledDropDown = styled(Dropdown)`
  &.ant-dropdown-trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  border-radius: 33px;
  border: solid 1px #e3e7ed;
  background-color: #fff;
  padding: 9px 22px 9px 25px;
  height: auto;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 1.43;
  color: #1d1e2b;
  width: 100%;
  margin-left: auto;
`;

type DropDownOverlayPropsType = {
  dropDownList: string[];
  onChange: (selectedValue: string) => void;
  setCurrentValue: React.Dispatch<React.SetStateAction<string>>;
  currentValue: string;
};

export const DropDownOverlay = (props: DropDownOverlayPropsType): JSX.Element => {
  const { dropDownList, currentValue, setCurrentValue, onChange } = props;
  const onClick = (selectedValue: string) => {
    if (selectedValue !== currentValue) {
      setCurrentValue(selectedValue);
      onChange(selectedValue);
    }
  };
  return (
    <Menu defaultSelectedKeys={[currentValue]} className="cr-dropdown-menu">
      {dropDownList.map((item) => (
        <Menu.Item onClick={() => onClick(item)} key={item} className={item === currentValue ? 'cr-selected' : ''}>
          {item}
        </Menu.Item>
      ))}
    </Menu>
  );
};

interface DropDownPropsType extends DropDownProps {
  value: string;
  width?: string | undefined;
}

export const CRDropDown = (props: DropDownPropsType): JSX.Element => {
  const { value, overlay, width, ...rest } = props;
  return (
    <StyledDropDownContainer width={width}>
      <StyledDropDown placement="bottomCenter" arrow={true} overlay={overlay} {...rest}>
        <Button>
          {value}
          <DownOutlined style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }} />
        </Button>
      </StyledDropDown>
    </StyledDropDownContainer>
  );
};
